import { apiCallback } from 'api';
import { DocumentNode } from 'graphql';
import query from 'api/definitions/substitutionProducts/get-substituion-products.graphql';

const definition = {
  graph: true,
  query: (): DocumentNode => query,
  variables: ({
    orderId,
    lineNumber,
    pickingRef,
  }: {
    orderId: string;
    lineNumber: string;
    pickingRef: string;
  }) => {
    return {
      orderId,
      lineNumber,
      pickingRef,
    };
  },
  queryString: { tag: 'get-substitution-products' },
};

export default {
  post: apiCallback(definition, 'post'),
};

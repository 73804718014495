import { TOGGLE_SWAP_UNAVAILABLE_PRODUCT_MODAL } from 'redux/modules/trolley/actions/types';

export function toggleSwapUnavailableProductModal(lineNumber, trolleyItemId, isOpen) {
  return dispatch =>
    dispatch({
      type: TOGGLE_SWAP_UNAVAILABLE_PRODUCT_MODAL,
      lineNumber,
      trolleyItemId,
      isOpen,
    });
}

import substitutionProducts from 'api/definitions/substitutionProducts';

import {
  GET_SUBSTITUTION_PRODUCTS,
  GET_SUBSTITUTION_PRODUCTS_SUCCESS,
  GET_SUBSTITUTION_PRODUCTS_FAILED,
} from 'redux/modules/trolley/actions/types';
import { LEGACY_PRODUCTS_SCHEMA } from 'redux/schemas';
import { TRANSFORM_PRODUCTS_INTO_LEGACY_FORMAT } from 'redux/transforms';

import { handleGenericTrolleyNetworkError } from 'redux/modules/trolley/error-handling';

export const getSubstitutionProducts = (lineNumber, origin, pickingRef) => (dispatch, getState) => {
  dispatch({ type: GET_SUBSTITUTION_PRODUCTS });
  return substitutionProducts
    .post({ lineNumber, pickingRef })({ dispatch, getState })
    .then(response => {
      if (!response) {
        dispatch({
          error: 'Graph returned a get substitution products error',
          type: GET_SUBSTITUTION_PRODUCTS_FAILED,
        });

        return;
      }

      const { data, errors = [] } = response;
      const failures = data?.getSubstitutionProducts?.failures || [];

      if (errors.length > 0 || !data?.getSubstitutionProducts || failures.length > 0) {
        dispatch({
          error: 'Graph returned a get substitution products error',
          type: GET_SUBSTITUTION_PRODUCTS_FAILED,
        });

        const error =
          failures.length || errors.length
            ? JSON.stringify([...failures, ...errors])
            : 'Unknown getSubstitutionProducts error';

        handleGenericTrolleyNetworkError({ dispatch, error });

        return;
      }

      dispatch({
        type: GET_SUBSTITUTION_PRODUCTS_SUCCESS,
        result: data.getSubstitutionProducts,
        lineNumber,
        analytics: {
          lineNumber,
          origin,
        },
        schema: LEGACY_PRODUCTS_SCHEMA,
        transform: TRANSFORM_PRODUCTS_INTO_LEGACY_FORMAT,
      });
    })
    .catch(error => {
      handleGenericTrolleyNetworkError({ dispatch, error });

      dispatch({
        error: 'Get substitution products call to graph failed',
        type: GET_SUBSTITUTION_PRODUCTS_FAILED,
      });
    });
};
